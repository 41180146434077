import { useParams } from 'react-router-dom';
import { useUnifiedAPI } from '../useUnifiedAPI';
import { useURLQuery } from '../useURLQuery';
import { CANADA } from '../../constants/utils';

export function useLocation(enabled: boolean = false) {
  const { country } = useParams();
  const { paramsArray } = useURLQuery();
  const postcodeParam = paramsArray.find((param) => param.name === 'postcode') || {
    name: 'postcode',
    value: '',
  };
  let isEnabled = enabled;

  const postcode =
    country === CANADA
      ? { ...postcodeParam, value: postcodeParam.value.replace(/\s+/g, '') }
      : postcodeParam;

  const params = [{ name: 'country', value: country || 'US' }, postcode];

  return useUnifiedAPI(
    '/location',
    params,
    { runDataTransformations: true },
    { enabled: isEnabled }
  );
}
